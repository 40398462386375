.work {
    /* align-items: center;
    justify-content: space-between; */
    display: flex;
}

.work-thumbnail {
    aspect-ratio: 1;
    margin-right: var(--margin-small);
}

.work-thumbnail-image {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: var(--margin-small);
}

.work-items {
    width: 100%;
}

.work-item-1, .work-item-2, .work-item-3, .work-item-4, .work-item-5 {
    align-items: center;
    justify-content: space-between;
    display: flex;

    padding: var(--margin-small) 0 var(--margin-small) 0;
    width: 100%;

    border-bottom: 3px solid var(--t);
}

.work-item-1 {
    border-top: 3px solid var(--t);
}

.work-item-1:hover h2 {
    color: var(--cta);

    transition: 350ms ease-out;
}

.work-item-2:hover h2 {
    color: var(--cta);

    transition: 350ms ease-out;
}

.work-item-3:hover h2 {
    color: var(--cta);

    transition: 350ms ease-out;
}

.work-item-4:hover h2 {
    color: var(--cta);

    transition: 350ms ease-out;
}

.work-item-5:hover h2 {
    color: var(--cta);

    transition: 350ms ease-out;
}

.work-item-image {
    min-width: 125px;
    width: 100%;

    transition: 350ms ease-out;
}

.work-item-tags {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 15rem;
}

.work-item-tags-arrow {
    width: 50px;

    -webkit-transform:rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);

    transition: 350ms ease-out;
}

.work-item-1:hover .work-item-tags-arrow { 
    margin-right: calc(var(--margin-small) * -1);

    transition: 350ms ease-out;
}

.work-item-2:hover .work-item-tags-arrow { 
    margin-right: calc(var(--margin-small) * -1);

    transition: 350ms ease-out;
}

.work-item-3:hover .work-item-tags-arrow { 
    margin-right: calc(var(--margin-small) * -1);

    transition: 350ms ease-out;
}

.work-item-4:hover .work-item-tags-arrow { 
    margin-right: calc(var(--margin-small) * -1);

    transition: 350ms ease-out;
}

@media (max-width: 1300px) {
    .hover-hidden {
        display: none;
    }
}

@media (max-width: 50em) {
    .work-item-tags-arrow {
        width: 2rem;
    }
}
  
@media (max-width: 37em) {
    .work-item-tags-arrow {
        width: 20px
    }

    .work-item-1:hover .work-item-tags-arrow { 
        margin-right: calc(var(--margin-mini) * -1);
    }

    .work-item-2:hover .work-item-tags-arrow { 
        margin-right: calc(var(--margin-mini) * -1);
    }

    .work-item-3:hover .work-item-tags-arrow { 
        margin-right: calc(var(--margin-mini) * -1);
    }

    .work-item-4:hover .work-item-tags-arrow { 
        margin-right: calc(var(--margin-mini) * -1);
    }
}