footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.footer-items-back-to-top {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin: var(--margin-big) 0 var(--margin-big) 0;
}

.footer-items-back-to-top-link {
    display: flex;
}

.footer-items-back-to-top-link p {
    margin-right: var(--margin-mini);
  }

.footer-items-back-to-top-link-arrow {
    display: flex;
    width: 20px;

    -webkit-transform:rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);

    transition: 350ms ease-out;
}

.footer-items-back-to-top-link:hover .footer-items-back-to-top-link-arrow { 
    margin-top: calc(var(--margin-small) * -1);

    transition: 350ms ease-out;
}

@media (max-width: 1815px) {

}

@media (max-width: 1150px) {
    .footer-items-copy {
        display: none;
    }
}

@media (max-width: 50em) {
    .footer-items-website {
        display: none;
    }

    .footer-items-copy {
        display: block;
    }
}
  
@media (max-width: 37em) {
    .footer-items-website {
        display: none;
    }

    .footer-items-copy {
        display: none;
    }
}