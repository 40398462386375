.about {
    display: flex;
}

.about-me {
    width: 33%;
    margin-right: calc(var(--margin-small)/2);
}

.about-profile {
    width: 33%;
    margin: 0 calc(var(--margin-small)/2) 0 calc(var(--margin-small)/2);
}

.about-profile-image {
    width: 100%;
}

.about-skills {
    width: 33%;
    margin-left: calc(var(--margin-small)/2);
}

.about-skills-expertise {
    display: flex; 
    flex-flow: row wrap;
}

.about-skills-expertise span {
    line-height: 30px;
    border: solid 3px var(--t);

    padding: var(--margin-mini);
    margin: calc(var(--margin-small)/2) var(--margin-small) calc(var(--margin-small)/2) 0;
}

.about-skills-tools {
    display: flex; 
    flex-flow: row wrap;
}

.about-skills-tools span {
    display: flex;
    align-items: center;
    white-space: nowrap;
    
    line-height: 30px;
    border: solid 3px var(--t);

    padding: var(--margin-mini);
    margin: calc(var(--margin-small)/2) var(--margin-small) calc(var(--margin-small)/2) 0;
}

.about-skills-tools img {
    padding-right: var(--margin-mini);
    max-height: 25px;
}

@media (max-width: 1815px) {
    .about {
        display: block;
    }
    .about-me {
        width: 100%;
        margin: 0;
    }
    
    .about-profile {
        display: none;
    }
    
    .about-skills {
        display: flex;
        width: 100%;
        margin: 0;
    }

    .about-skills h5 {
        margin-top: var(--margin-small);
    }
}

@media (max-width: 1100px) {
    .about-skills {
        display: block;
    }
}

@media (max-width: 50em) {
  }
  
  @media (max-width: 37em) {
    .about {
        display: block;
    }

    .about-skills-expertise span {
        margin: calc(var(--margin-mini)/2) var(--margin-mini) calc(var(--margin-mini)/2) 0;
    }

    .about-skills-tools span {
        margin: calc(var(--margin-mini)/2) var(--margin-mini) calc(var(--margin-mini)/2) 0;
    }
}