.contact span {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: var(--margin-mini) 0;
    border-top: solid 3px var(--t);

    font-family: -apple-system, 'CabinetGrotesk-Medium', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 30px;
    color: var(--t);
    margin: 0;
}

.contact span:hover {
    color: var(--cta);

    transition: 350ms ease-out;
}

.contact img {
    -webkit-transform:rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.contact-arrow-1, .contact-arrow-2, .contact-arrow-3, .contact-arrow-4 {
        display: flex;
        width: 20px;
    
        -webkit-transform:rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
    
        transition: 350ms ease-out;
}

.contact-arrow:hover .contact-arrow-1 { 
    margin-top: calc(var(--margin-small) * -1);
    margin-right: calc(var(--margin-small) * -0.5);

    transition: 350ms ease-out;
}
.contact-arrow:hover .contact-arrow-2 { 
    margin-top: calc(var(--margin-small) * -1);
    margin-right: calc(var(--margin-small) * -0.5);

    transition: 350ms ease-out;
}
.contact-arrow:hover .contact-arrow-3 { 
    margin-top: calc(var(--margin-small) * -1);
    margin-right: calc(var(--margin-small) * -0.5);

    transition: 350ms ease-out;
}
.contact-arrow:hover .contact-arrow-4 { 
    margin-top: calc(var(--margin-small) * -1);
    margin-right: calc(var(--margin-small) * -0.5);

    transition: 350ms ease-out;
}

.contact-last {
    border-bottom: solid 3px var(--t);
}