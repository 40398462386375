header {
    margin-top: calc(var(--margin-big)/2);
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.header-intro {
    margin-right: var(--margin-small);
}

.header-profile-image {
    min-width: 500px;
    max-width: 100%;
}

@media (max-width: 1600px) {
    .header-hidden {
        display: none;
    }

    .header-profile-image {
        min-width: 450px;
        max-width: 100%;
    }
}

@media (max-width: 1370px) {
    .header-profile-image {
        min-width: 400px;
    }
}

@media (max-width: 1150px) {
    .header-profile-image {
        min-width: 350px;
    }
}

@media (max-width: 50em) {
    header {
        display: block;
    }

    .header-profile {
        margin-top: var(--margin-small);
    }

    .header-profile-image {
        min-width: 100%;
    }
}
  
@media (max-width: 37em) {
}